import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import YoutubeVideos from "../components/YoutubeVideos"
import Heading from "../components/heading"

const VideosPage = () => (
  <Layout>
    <SEO title="Vídeos" />
    <Container>
      <br/>
      <Heading center className="cl-identity">Vídeos</Heading>
      <Heading secondary center>
        Inscreva-se em meu canal do Youtube e assista aos meus vídeos!
      </Heading>
      <YoutubeVideos />
    </Container>
  </Layout>
)

export default VideosPage
