import React from "react"
import { Card, Col } from "react-bootstrap"
import Img from "gatsby-image"

const VideoCard = ({ video }) => (
  <Col lg="6" className="my-3" key={video.id}>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={`https://youtu.be/${video.videoId}`}
      className="text-decoration-none"
    >
      <Card className="shadow rounded-lg h-100 bg-identity bd-identity-8">
        <Card.Img
          variant="top"
          as={Img}
          fluid={video.localThumbnail.childImageSharp.fluid}
          fadeIn
          durationFadeIn={1000}
          alt="Card image"
        />

        <Card.Body>
          <Card.Title className="cl-fundo font-header">
            {video.title}
          </Card.Title>
        </Card.Body>
      </Card>
    </a>
  </Col>
)

export default VideoCard
