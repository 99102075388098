import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import { Container, Row } from "react-bootstrap"

import VideoCard from "../VideoCard"

class YoutubeVideos extends React.Component {
  render() {
    const { data } = this.props
    const { edges: videos } = data.allYoutubeVideo

    return (
      <Container>
        <Row>
          {videos &&
            videos.map(({ node: video }) => (
              <VideoCard key={video.id} video={video} />
            ))}
        </Row>
      </Container>
    )
  }
}

YoutubeVideos.propTypes = {
  data: PropTypes.shape({
    allYoutubeVideo: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query YoutubeVideosQuery {
        allYoutubeVideo(sort: { fields: publishedAt, order: DESC }) {
          edges {
            node {
              id
              title
              description
              videoId
              publishedAt
              localThumbnail {
                childImageSharp {
                  fluid(
                    maxWidth: 900
                    maxHeight: 500
                    fit: COVER
                    cropFocus: CENTER
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <YoutubeVideos data={data} count={count} />}
  />
)
